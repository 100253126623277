import { OptionType } from '@shipae/components-sandbox/component/select';

export const createReasonOptions = (
  t: (key: string) => string,
): OptionType[] => ([
  {
    value: '38',
    label: t('SDBusinessCallBack.delays'),
  },
  {
    value: '39',
    label: t('SDBusinessCallBack.pickUpRequired'),
  },
  {
    value: '40',
    label: t('SDBusinessCallBack.changeRequest'),
  },
  {
    value: '4',
    label: t('other'),
  },
]);
