import React, { useMemo, useEffect } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useLocation } from '@reach/router';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
import { useTranslation } from 'src/views/misc/localization';
import {
  getSdWorkWithUsIsModuleLoaded,
  getSdWorkWithUsData,
} from 'src/application/lazy/sd-work-with-us/selectors';
import {
  setRequiredEmail,
  setRequiredInput,
  setRequiredPhoneInput,
  submitForm,
  setGeneralError,
  setCheckbox,
  resetForm,
} from 'src/application/lazy/sd-work-with-us/actions';
import Select from '@shipae/components-sandbox/component/select';
import SelectTheme from '@shipae/components-sandbox/component/select/theme/shipa-delivery'; // eslint-disable-line max-len
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/shipa-delivery'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/shipa-delivery'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/shipa-delivery'; // eslint-disable-line max-len
import Checkbox
  from '@shipae/components-sandbox/component/checkbox'; // eslint-disable-line max-len
import { sd300 }
  from '@shipae/components-sandbox/component/colors'; // eslint-disable-line max-len
import CheckboxTheme
  from '@shipae/components-sandbox/component/checkbox/theme/shipa-delivery'; // eslint-disable-line max-len
import PhoneInputTheme
  from '@shipae/components-sandbox/component/phone-input/theme/shipa-delivery'; // eslint-disable-line max-len
import { parseQueryString } from 'src/views/utils/parse-query-string';
import config from 'src/config';
import { loadComponent } from 'src/application/core/control/actions';
import { createDailyVolumeOptions, createIndustryOptions } from './options';
import {
  ConsentStatement,
  FlexChild,
  Form,
  GeneralError,
  RecaptchaError as FieldError,
} from '../shared.styles';

const SDBusinessWorkWithUsForm: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('sd-work-with-us'));

    return () => dispatch(resetForm);
  }, []);
  const location = useLocation();
  const { t } = useTranslation();
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    companyName,
    industry,
    dailyVolume,
    isLoading,
    isSuccess,
    recaptcha,
    receiveEmails,
    generalError,
  } = useSelector(getSdWorkWithUsData);
  const isModuleLoaded = useSelector(getSdWorkWithUsIsModuleLoaded);

  useEffect(() => {
    if (!location || !location.search) {
      return;
    }
    const query = parseQueryString(location.search);

    if (isModuleLoaded && query.user) {
      dispatch(setRequiredInput('firstName')(query.user));
    }
    if (isModuleLoaded && query.email) {
      dispatch(setRequiredEmail('email')(query.email));
    }
  }, [isModuleLoaded]);

  const industryOptions = useMemo(
    () => createIndustryOptions(t),
    [t],
  );
  const industryLabel = useMemo(
    () => {
      if (!industry.value) return '';
      const option = industryOptions.find((o) => o.value === industry.value);

      return option?.label || '';
    },
    [t, industry.value, industryOptions],
  );
  const dailyVolumeOptions = useMemo(
    () => createDailyVolumeOptions(t),
    [t],
  );
  const dailyVolumeLabel = useMemo(
    () => {
      if (!dailyVolume.value) return '';
      const option = dailyVolumeOptions.find(
        (o) => o.value === dailyVolume.value,
      );

      return option?.label || '';
    },
    [t, dailyVolume.value, dailyVolumeOptions],
  );

  if (isSuccess) {
    if (typeof document !== 'undefined') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return null;
  }

  return (
    <Form
      data-test="sd-work-with-us-form"
      onSubmit={(e) => {
        e.preventDefault();

        dispatch(submitForm);
        dispatch(setGeneralError(''));
      }}
    >
      <FlexChild hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('firstName')}
          hasValue={!!firstName.value}
          error={t(firstName.error)}
          disabled={isLoading}
          data-test="sd-work-with-us-form-first-name"
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={firstName.value}
            error={!!firstName.error}
            onChange={
              (newVal) => dispatch(
                setRequiredInput('firstName')(newVal),
              )
              }
          />
        </FormElement>
      </FlexChild>
      <FlexChild>
        <FormElement
          theme={FormElementTheme}
          label={t('lastName')}
          hasValue={!!lastName.value}
          error={t(lastName.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={lastName.value}
            error={!!lastName.error}
            onChange={
            (newVal) => dispatch(
              setRequiredInput('lastName')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild hasRightPadding width="50%">
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          required
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={PhoneInputTheme}
        />
      </FlexChild>
      <FlexChild>
        <FormElement
          theme={FormElementTheme}
          label={t('email')}
          hasValue={!!email.value}
          error={t(email.error)}
          disabled={isLoading}
          data-test="sd-work-with-us-form-email"
          required
        >
          <Input
            theme={InputTheme}
            type="email"
            value={email.value}
            error={!!email.error}
            onChange={
            (newVal) => dispatch(
              setRequiredEmail('email')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('companyName')}
          hasValue={!!companyName.value}
          error={t(companyName.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={companyName.value}
            error={!!companyName.error}
            onChange={
            (newVal) => dispatch(
              setRequiredInput('companyName')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild>
        <FormElement
          theme={FormElementTheme}
          label={t('selectYourIndustry')}
          hasValue={!!industry.value}
          error={t(industry.error)}
          disabled={isLoading}
          required
        >
          <Select
            theme={SelectTheme}
            error={!!industry.error}
            options={industryOptions}
            value={{
              label: industryLabel,
              value: industry.value,
            }}
          // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('industry')(option?.value || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild>
        <FormElement
          theme={FormElementTheme}
          label={t('yourDailyVolume')}
          hasValue={!!dailyVolume.value}
          error={t(dailyVolume.error)}
          disabled={isLoading}
          required
        >
          <Select
            theme={SelectTheme}
            error={!!dailyVolume.error}
            options={dailyVolumeOptions}
            value={{
              label: dailyVolumeLabel,
              value: dailyVolume.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('dailyVolume')(option?.value || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="100%" marginBottom="4rem">
        <Recaptcha
          sitekey={
            process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <FieldError visible={!!recaptcha.error}>
          {t(recaptcha.error)}
        </FieldError>
      </FlexChild>
      <ConsentStatement>
        <FormElement
          theme={FormElementTheme}
          hasValue={receiveEmails.value.agreed}
          error={t(receiveEmails.error)}
          disabled={isLoading}
          data-test="delivery-fleet-policy"
          required
        >
          <Checkbox
            checked={receiveEmails.value.agreed}
            onChange={(
              val: boolean,
            ) => dispatch(
              setCheckbox(
                'receiveEmails',
                'agreed',
              )(!!val),
            )}
            color={sd300()}
            theme={CheckboxTheme}
            label={t('SDBusinessWorkWithUs.agreeToReceiveCommunications')}
          />
        </FormElement>
      </ConsentStatement>
      {generalError ? (
        <FlexChild width="100%">
          <GeneralError>
            {generalError}
          </GeneralError>
        </FlexChild>
      ) : null}
      <PrimaryButton
        type="submit"
        loading={isLoading}
        theme={ButtonTheme}
      >
        {t('callMeBack')}
      </PrimaryButton>
    </Form>
  );
};

export default SDBusinessWorkWithUsForm;
