import { AppState } from 'src/application/state';
import {
  initialState,
  SubmitResumeData,
} from './reducer';

export const getSubmitResumeData = (
  state: AppState,
): SubmitResumeData => (
  state.submitResume
    ? state.submitResume
    : initialState
);
