import {
  errorText,
  errorBg,
} from '@shipae/components-sandbox/component/colors';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import { fonts, media } from 'src/views/theme';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

export const FormWithPadding = styled(Form)`
  padding: 5rem 3rem;
`;

interface FlexChildProps {
  hasRightPadding?: boolean;
  hasSmallRightPadding?: boolean;
  width?: string;
  marginBottom?: string;
}

export const FlexChild = styled.div<FlexChildProps>`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${ (pr) => (pr.width ? pr.width : '40%') };

  margin-bottom: ${ (pr) => (pr.marginBottom ? pr.marginBottom : '2rem') };
  padding-right: ${ (pr) => (pr.hasRightPadding ? '4rem' : '0') };

  ${ (pr) => (pr.hasSmallRightPadding ? css`
    padding-right: 1.1rem;
  ` : '') }

  ${ media.max(1100) } {
    flex-basis: 90%;
    padding-right: 0;
    margin-bottom: 1rem;
  }

  ${ media.sm } {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
`;

export const Container = styled.div<{width?: string}>`
  width: ${ (pr) => (pr.width ? pr.width : '100%') };

  ${ media.max(1100) } {
    width: 100%;
  }

  ${ media.sm } {
    width: 100%;
  }
`;

export const GeneralError = styled.p`
  color: ${ errorText() };
  font-size: 1.4rem;
  background-color: ${ errorBg() };
  padding: 2rem 4rem 2rem 1rem;
  display: inline-block;
`;

export const WrongAccountError = styled(GeneralError)`
  background-color: transparent;
  padding: 0;
  margin-top: -3rem;
  display: block;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const RecaptchaError = styled.p<{visible?: boolean}>`
  color: ${ errorText() };
  font-size: 1.2rem;
`;

type ConsentStatementProps = {
  isHalfWidth?: boolean;
}

export const ConsentStatement = styled.p<ConsentStatementProps>`
  ${ fonts.bodys200 };
  flex-shrink: 0;
  flex-basis: ${ (pr) => (pr.isHalfWidth ? '50%' : '100%') };
  margin-bottom: 3rem;
`;

export const RichTextEx = styled(RichText)`
  margin-bottom: 3rem;
`;
