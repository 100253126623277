import { AppState } from 'src/application/state';
import {
  initialState,
  SecGetInTouchData,
} from './reducer';

export const getSecGetInTouchIsModuleLoaded = (
  state: AppState,
): boolean => (
  state.secGetInTouch
    ? state.secGetInTouch.isModuleLoaded
    : initialState.isModuleLoaded
);

export const getSecGetInTouchForm = (
  state: AppState,
): SecGetInTouchData => (
  state.secGetInTouch
    ? state.secGetInTouch
    : initialState
);
