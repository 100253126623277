import { AppState } from '../../state';
import { initialState, JoinDeliveryFleetData } from './reducer';

export const getJoinDeliveryFleetData = (
  state: AppState,
): JoinDeliveryFleetData => (
  state.joinDeliveryFleet
    ? state.joinDeliveryFleet
    : initialState
);
