import React, { ReactNode } from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import { media } from 'src/views/theme';
import styled from 'styled-components';
import { RichTextEx } from '../shared.styles';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type Props = {
  text?: string;
  image: ReactNode;
}

const FormInfoWrapper = styled.div`
  margin-right: 7rem;
`;

const DesktopOnly = styled.div`
  ${ media.md } {
    display: none;
  }
`;

const FormInfo: React.FC<Props> = ({
  text,
  image,
}) => (
  <FormInfoWrapper>
    <RichTextEx>
      <ReactMarkdown options={gloabalReactMdOptions}>
        {text || ''}
      </ReactMarkdown>
    </RichTextEx>
    <DesktopOnly>
      {image}
    </DesktopOnly>
  </FormInfoWrapper>
);

export default FormInfo;
