import { AppState } from 'src/application/state';
import { initialState, SecCallbackData } from './reducer';

export const getSecCallbackData = (
  state: AppState,
): SecCallbackData => (
  state.secCallback
    ? state.secCallback
    : initialState
);
