import { OptionType } from '@shipae/components-sandbox/component/select';

export const createYearsOfExperienceOptions = (
): OptionType[] => Array.from(new Array(15)).map((_, index) => {
  if (index < 14) {
    const option = `${ index + 1 } year${ index > 0 ? 's' : '' }`;
    return {
      value: option,
      label: option,
    };
  }
  return {
    value: '15+ years',
    label: '15+ years',
  };
});
