import styled, { css } from 'styled-components';
import { GatsbyImageProps } from 'gatsby-image';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { media } from 'src/views/theme';
import { g100, white } from '@shipae/components-sandbox/component/colors';

export const Section = styled.section`
  padding: 9rem calc((100% - var(--container-width)) / 2);
`;

export const GrayBackgroundSection = styled(Section)`
  background-color: ${ g100() };

  display: flex;
  align-items: flex-start;
  & > * {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 40%;

    &:nth-child(2) {
      flex-basis: 60%;
    }
  }

  ${ media.md } {
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const ImageRegularBaseCss = css`
  width: 44.8rem;
  height: 44.8rem;
  margin: 0 auto;

  ${ media.max(1225) } {
    width: 34.8;
    height: 34.8;
  }

  ${ media.md } {
    width: 100%;
  }
`;

type ImageProps = {
  margin?: string;
}

export const ImageSvg = styled.img<ImageProps>`
  ${ ImageRegularBaseCss }

  ${ (pr) => (pr.margin ? css`
    margin: ${ pr.margin };
  ` : '') }
`;

export const ImageRegular = styled(GatsbyImage)<GatsbyImageProps & ImageProps>`
  ${ ImageRegularBaseCss }

${ (pr) => (pr.margin ? css`
  margin: ${ pr.margin };
` : '') }
`;

export const WhiteBox = styled.div<{isHidden: boolean}>`
  display: ${ (pr) => (pr.isHidden ? 'none' : 'block') };
  background-color: ${ white() };
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07);
`;
