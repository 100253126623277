import { SetCampaign } from 'application/lazy/sec-get-in-touch/actions';

export type Output = SetCampaign['payload'];

//* If latest traffic campaign is available, return it */
//* Otherwise return initial traffic campaign */
export const getCampaign = (
  latestTrafficSource: Output,
  initialTrafficCookie?: string,
):Output => {
  if (
    latestTrafficSource?.name
    || latestTrafficSource?.medium
    || latestTrafficSource?.source
  ) {
    return {
      name: latestTrafficSource?.name || '',
      medium: latestTrafficSource?.medium || '',
      source: latestTrafficSource?.source || '',
    };
  }
  const campaign: Output = {
    source: '',
    name: '',
    medium: '',
  };
  if (!initialTrafficCookie) {
    return campaign;
  }

  const items = initialTrafficCookie.split('|');

  items.forEach((item) => {
    const [key, valueInParentheses] = item.split('=');

    const value = valueInParentheses.replace(/[()]/g, '');

    switch (key) {
      case 'utmcsr':
        campaign.source = value;
        break;
      case 'utmcmd':
        campaign.medium = value;
        break;
      case 'utmccn':
        campaign.name = value;
        break;
      default:
        break;
    }
    return campaign;
  });

  return campaign;
};
