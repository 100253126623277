import { AppState } from 'src/application/state';
import { initialState, SdBusinessCallbackData } from './reducer';

export const getSdBusinessCallbackData = (
  state: AppState,
): SdBusinessCallbackData => (
  state.sdBusinessCallback
    ? state.sdBusinessCallback
    : initialState
);
