import { AppState } from 'src/application/state';
import { initialState, SdConsumerCallbackData } from './reducer';

export const getSdConsumerCallbackData = (
  state: AppState,
): SdConsumerCallbackData => (
  state.sdConsumerCallback
    ? state.sdConsumerCallback
    : initialState
);
