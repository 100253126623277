import { OptionType } from '@shipae/components-sandbox/component/select';

export const createReasonOptions = (
  t: (key: string) => string,
): OptionType[] => ([
  {
    value: '42',
    label: t('SDConsumerCallBack.lateDelivery'),
  },
  {
    value: '43',
    label: t('SDConsumerCallBack.urgentDelivery'),
  },
  {
    value: '44',
    label: t('SDConsumerCallBack.cancelOrders'),
  },
  {
    value: '45',
    label: t('SDConsumerCallBack.changeAddress'),
  },
  {
    value: '46',
    label: t('SDConsumerCallBack.wrongOrder'),
  },
  {
    value: '4',
    label: t('other'),
  },
]);
