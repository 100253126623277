import React, { useEffect, useMemo } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import FormElement from '@shipae/components-sandbox/component/form-element';
// eslint-disable-next-line max-len
import FormElementTheme from '@shipae/components-sandbox/component/form-element/theme/shipa-ecommerce';
import Input from '@shipae/components-sandbox/component/input';
// eslint-disable-next-line max-len
import InputTheme from '@shipae/components-sandbox/component/input/theme/shipa-ecommerce';
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
// eslint-disable-next-line max-len
import PhoneInputTheme from '@shipae/components-sandbox/component/phone-input/theme/shipa-ecommerce';
import { useTranslation } from 'src/views/misc/localization';
// eslint-disable-next-line max-len
import PrimaryButton from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/shipa-ecommerce'; // eslint-disable-line max-len
import {
  setRequiredEmail,
  setRequiredInput,
  setInput,
  setRequiredPhoneInput,
  setCheckbox,
  submitForm,
  setCampaign,
  resetForm,
  setRequiredMultiSelect,
} from 'application/lazy/sec-get-in-touch/actions';
import {
  getSecGetInTouchIsModuleLoaded,
  getSecGetInTouchForm,
} from 'application/lazy/sec-get-in-touch/selectors';
import config from 'src/config';
import Select from '@shipae/components-sandbox/component/select';
// eslint-disable-next-line max-len
import SelectTheme from '@shipae/components-sandbox/component/select/theme/shipa-ecommerce'; // eslint-disable-line max-len
import TextArea from '@shipae/components-sandbox/component/textarea';
// eslint-disable-next-line max-len
import TextAreaTheme from '@shipae/components-sandbox/component/textarea/theme/shipa-ecommerce'; // eslint-disable-line max-len
import Checkbox from '@shipae/components-sandbox/component/checkbox';
// eslint-disable-next-line max-len
import RadioButtonTheme from '@shipae/components-sandbox/component/radio-button/theme';
import RadioButton from '@shipae/components-sandbox/component/radio-button';
import { ecoGreen } from '@shipae/components-sandbox/component/colors';
import { parseQueryString } from 'src/views/utils/parse-query-string';
import styled from 'styled-components';
import { countries } from 'src/application/constants/countries';
import { loadComponent } from 'src/application/core/control/actions';
import {
  FlexChild,
  FormWithPadding,
  GeneralError,
  RecaptchaError,
} from '../shared.styles';
import { getCampaign, Output } from './utils';

const CustomPhoneInputTheme = {
  ...PhoneInputTheme,
  SelectContainer: styled(PhoneInputTheme.SelectContainer)`
    flex-basis: 10rem;
  `,
};

declare global {
  interface Window {
    Cypress: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

const capitalizeWord = (
  word: string,
) => word[0].toUpperCase() + word.substring(1);

const SECGetInTouch: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    isSuccess,
    isLoading,
    firstName,
    lastName,
    companyName,
    phoneNumber,
    email,
    websiteLink,
    country,
    city,
    ordersPerMonth,
    hasTradeLicense,
    kindOfCommodities,
    additionalDetails,
    agreeToTermsAndConditions,
    recaptcha,
    generalError,
  } = useSelector(getSecGetInTouchForm);
  const isModuleLoaded = useSelector(getSecGetInTouchIsModuleLoaded);

  useEffect((): () => void => {
    dispatch(loadComponent('sec-get-in-touch'));

    return () => dispatch(resetForm);
  }, []);

  useEffect(() => {
    if (!location || !isModuleLoaded) {
      return;
    }

    const query = parseQueryString(location.search);

    const latestTrafficSource: Output = {
      name: query.utm_campaign,
      source: query.utm_source,
      medium: query.utm_medium,
    };

    const {
      name,
      source,
      medium,
    } = getCampaign(
      latestTrafficSource,
      Cookies.get('initialTrafficSource'),
    );

    dispatch(setCampaign({
      name,
      source,
      medium,
    }));
  }, [isModuleLoaded]);

  const commoditiesOptions = [
    {
      value: 'Electronics',
      label: t('electronics'),
    },
    {
      value: 'Fashion',
      label: t('fashion'),
    },
  ];

  const commoditiesValues = useMemo(
    () => {
      const values = kindOfCommodities.values.map(
        (optionValue) => {
          const option = commoditiesOptions.find(
            (o) => o.value === optionValue,
          ) || {
            // at this point optionValue must be others
            value: optionValue,
            label: capitalizeWord(optionValue),
          };

          return ({
            value: option.value,
            label: option.label,
          });
        },
      );

      return values;
    },
    [kindOfCommodities.values, commoditiesOptions],
  );

  useEffect(() => {
    if (isSuccess) {
      if (typeof document !== 'undefined') {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  }, [isSuccess]);

  if (isSuccess) {
    return null;
  }

  return (
    <FormWithPadding
      data-test="sec-get-in-touch"
      onSubmit={(e) => {
        e.preventDefault();

        dispatch(submitForm);
      }}
    >
      <FlexChild width="30%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('firstName')}
          hasValue={!!firstName.value}
          error={t(firstName.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-first-name"
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={firstName.value}
            error={!!firstName.error}
            autocomplete="given-name"
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('firstName')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="30%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('lastName')}
          hasValue={!!lastName.value}
          error={t(lastName.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-last-name"
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={lastName.value}
            error={!!lastName.error}
            autocomplete="family-name"
            onChange={
            (newVal) => dispatch(
              setRequiredInput('lastName')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="40%">
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          required
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={CustomPhoneInputTheme}
          autocomplete="tel-national"
          data-test="sec-get-in-touch-phone-input"
        />
      </FlexChild>
      <FlexChild width="50%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('emailGetInTouch')}
          hasValue={!!email.value}
          error={t(email.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-email"
          required
        >
          <Input
            theme={InputTheme}
            type="email"
            value={email.value}
            error={!!email.error}
            autocomplete="email"
            onChange={
            (newVal) => dispatch(
              setRequiredEmail('email')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          label={t('companyName')}
          hasValue={!!companyName.value}
          error={t(companyName.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-company-name"
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={companyName.value}
            error={!!companyName.error}
            autocomplete="organization"
            onChange={
            (newVal) => dispatch(
              setRequiredInput('companyName')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('websiteLink')}
          hasValue={!!websiteLink.value}
          error={t(websiteLink.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-website"
        >
          <Input
            theme={InputTheme}
            type="text"
            value={websiteLink.value}
            error={!!websiteLink.error}
            autocomplete="url"
            onChange={
            (newVal) => dispatch(
              setInput('websiteLink')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="25%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('country')}
          hasValue={!!country.value}
          error={t(country.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-country"
          required
        >
          <Select
            theme={SelectTheme}
            error={!!country.error}
            options={countries}
            value={{
              label: country.value,
              value: country.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('country')(option?.label || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="25%">
        <FormElement
          theme={FormElementTheme}
          label={t('city')}
          hasValue={!!city.value}
          error={t(city.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-city"
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={city.value}
            error={!!city.error}
            onChange={
            (newVal) => dispatch(
              setRequiredInput('city')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          hasValue={!!ordersPerMonth.value}
          label={t('ordersYouProcessPerMonth')}
          error={t(ordersPerMonth.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-orders"
          required
        >
          <RadioButton
            id="lessThan1000"
            checked={ordersPerMonth.value === 'Less than 1,000'}
            groupName="ordersPerMonth"
            label={t('ordersPerMonth.lessThan1000')}
            onClick={() => {
              dispatch(setRequiredInput(
                'ordersPerMonth',
              )('Less than 1,000'));
            }}
            theme={RadioButtonTheme}
          />
          <RadioButton
            id="between1000And5000"
            checked={ordersPerMonth.value === 'Between 1,000 and 5,000'}
            groupName="ordersPerMonth"
            label={t('ordersPerMonth.between1000And5000')}
            onClick={() => dispatch(setRequiredInput(
              'ordersPerMonth',
            )('Between 1,000 and 5,000'))}
            theme={RadioButtonTheme}
          />
          <RadioButton
            id="moreThan5000"
            checked={ordersPerMonth.value === 'More than 5,000'}
            groupName="ordersPerMonth"
            label={t('ordersPerMonth.moreThan5000')}
            onClick={() => dispatch(setRequiredInput(
              'ordersPerMonth',
            )('More than 5,000'))}
            theme={RadioButtonTheme}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          hasValue={!!hasTradeLicense.value}
          label={t('doYouHaveTradeLicense')}
          error={t(hasTradeLicense.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-license"
          required
        >
          <RadioButton
            id="yes"
            checked={hasTradeLicense.value === 'Yes'}
            groupName="hasTradeLicense"
            label={t('yes')}
            onClick={() => dispatch(setRequiredInput(
              'hasTradeLicense',
            )('Yes'))}
            theme={RadioButtonTheme}
          />
          <RadioButton
            id="no"
            checked={hasTradeLicense.value === 'No'}
            groupName="hasTradeLicense"
            label={t('no')}
            onClick={() => dispatch(setRequiredInput(
              'hasTradeLicense',
            )('No'))}
            theme={RadioButtonTheme}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          hasValue={
            !!kindOfCommodities.values
            && !!kindOfCommodities.values.length
          }
          label={t('whatKindOfCommodities')}
          error={t(kindOfCommodities.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-commodities"
          required
        >
          <Select
            options={commoditiesOptions}
            value={commoditiesValues}
            onChange={
              (values) => {
                if (Array.isArray(values)) {
                  dispatch(setRequiredMultiSelect(
                    'kindOfCommodities',
                  )(values.map((v) => v.value)));
                }
              }
            }
            multiple
            initialOthersOption={{
              value: 'others',
              label: 'Others',
            }}
            theme={SelectTheme}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="100%">
        <FormElement
          theme={FormElementTheme}
          label={t('additionalDetails')}
          hasValue={!!additionalDetails.value}
          error={t(additionalDetails.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-additional-details"
        >
          <TextArea
            theme={TextAreaTheme}
            type="text"
            value={additionalDetails.value}
            error={!!additionalDetails.error}
            onChange={
              (newVal) => dispatch(
                setInput('additionalDetails')(newVal),
              )
            }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          hasValue={agreeToTermsAndConditions.value.agreed}
          error={t(agreeToTermsAndConditions.error)}
          disabled={isLoading}
          data-test="sec-get-in-touch-agreement"
          required
        >
          <Checkbox
            checked={agreeToTermsAndConditions.value.agreed}
            label={t('SECGetInTouch.agreeToTerms')}
            onChange={(
              val: boolean,
            ) => dispatch(
              setCheckbox(
                'agreeToTermsAndConditions',
                'agreed',
              )(!!val),
            )}
            color={ecoGreen()}
          />
        </FormElement>
      </FlexChild>
      <FlexChild
        width="50%"
        marginBottom="4rem"
        data-test="sec-get-in-touch-recaptcha"
      >
        <Recaptcha
          sitekey={
            typeof window !== 'undefined' && window.Cypress
              ? config.testRecaptchaSiteKey : (
                process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey)
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <RecaptchaError
          visible={!!recaptcha.error}
          data-test="sec-get-in-touch-recaptcha-error"
        >
          {t(recaptcha.error)}
        </RecaptchaError>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%" data-test="sec-get-in-touch-general-error">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%" data-test="sec-get-in-touch-button-container">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
          data-test="sec-get-in-touch-submit"
        >
          {t('submit')}
        </PrimaryButton>
      </FlexChild>
    </FormWithPadding>
  );
};

export default SECGetInTouch;
