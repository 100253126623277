import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadComponent } from 'src/application/core/control/actions';
import { resetForm } from 'src/application/lazy/sd-business-callback/actions';
import {
  getSdBusinessCallbackData,
} from 'src/application/lazy/sd-business-callback/selectors';
import RequestCallback from './request-callback';
import VerifyAccount from './verify-account';

const SDBusinessCallBackForm: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('sd-business-callback'));

    return () => dispatch(resetForm);
  }, []);
  const {
    isSuccess,
    isSecondStep,
  } = useSelector(getSdBusinessCallbackData);

  if (isSuccess) {
    if (typeof document !== 'undefined') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return null;
  }

  return (
    !isSecondStep ? (
      <VerifyAccount />
    ) : (
      <RequestCallback />
    )
  );
};

export default SDBusinessCallBackForm;
