export enum Forms {
  ShipaSupplier = 'ShipaSupplier',
  ShipaGlobalTracking = 'ShipaGlobalTracking',
  SECCallBack = 'SECCallBack',
  SDBusinessWorkWithUs = 'SDBusinessWorkWithUs',
  SDBusinessCallBack = 'SDBusinessCallBack',
  SDConsumerCallBack = 'SDConsumerCallBack',
  SubmitYourResume = 'SubmitYourResume',
  JoinDeliveryFleet = 'JoinDeliveryFleet',
  SECGetInTouch = 'SECContactUs',
  TrackShipment = 'TrackShipment'
}
