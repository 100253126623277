import { OptionType } from '@shipae/components-sandbox/component/select';

export const createIndustryOptions = (
  t: (key: string) => string,
): OptionType[] => ([
  {
    value: '2',
    label: t('SDBusinessWorkWithUs.ecommerce'),
  },
  {
    value: '6',
    label: t('SDBusinessWorkWithUs.telecom'), // create in strapi
  },
  {
    value: '7',
    label: t('SDBusinessWorkWithUs.medical'), // create in strapi
  },
  {
    value: '8',
    label: t('SDBusinessWorkWithUs.retail'), // create in strapi
  },
  {
    value: '10',
    label: t('SDBusinessWorkWithUs.finance'), // create in strapi
  },
  {
    value: '19',
    label: t('SDBusinessWorkWithUs.groceries'), // create in strapi
  },
]);

export const createDailyVolumeOptions = (
  t: (key: string) => string,
): OptionType[] => ([
  {
    value: '7',
    label: t('SDBusinessWorkWithUs.volume7'),
  },
  {
    value: '8',
    label: t('SDBusinessWorkWithUs.volume8'),
  },
  {
    value: '9',
    label: t('SDBusinessWorkWithUs.volume9'),
  },
  {
    value: '10',
    label: t('SDBusinessWorkWithUs.volume10'),
  },
  {
    value: '11',
    label: t('SDBusinessWorkWithUs.volume11'),
  },
  {
    value: '12',
    label: t('SDBusinessWorkWithUs.volume12'),
  },
  {
    value: '13',
    label: t('SDBusinessWorkWithUs.volume13'),
  },
]);
