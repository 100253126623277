import { OptionType } from '@shipae/components-sandbox/component/select';

export const createTitleOptions = (
  t: (key: string) => string,
): OptionType[] => ([
  {
    value: 'Mr.',
    label: t('mr'),
  },
  {
    value: 'Mrs.',
    label: t('mrs'),
  },
  {
    value: 'Ms.',
    label: t('ms'),
  },
]);
