import react from 'react'; // eslint-disable-line
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageFluidProps } from 'gatsby-image';

type FilesystemImage = {
  id: string;
  childImageSharp?: GatsbyImageFluidProps;
  publicURL?: string;
}

export type ImageData = {
  image: FilesystemImage;
}

export const useSuccessImageFallback = (
): ImageData => useStaticQuery<ImageData>(graphql`
    query {
      image: file(
        relativePath: {eq: "form-success-fallback.png"}
      ) {
        # localFile { No need to use localFile as its not Strapi plugin
          childImageSharp {
            fluid(quality: 100, maxWidth: 448) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        # }
      }
    }
  `);
