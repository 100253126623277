import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Recaptcha from 'react-google-recaptcha';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
import { useTranslation } from 'src/views/misc/localization';
import {
  setInput,
  setRequiredEmail,
  setRequiredInput,
  setRequiredPhoneInput,
  submitForm,
  setRequiredPdfOrWordFile,
  setRequiredCheckbox,
  resetForm,
} from 'src/application/lazy/join-delivery-fleet/actions';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import FileInput from '@shipae/components-sandbox/component/file-input';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/neutral'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/neutral'; // eslint-disable-line max-len
import PhoneInputTheme
  from '@shipae/components-sandbox/component/phone-input/theme/neutral'; // eslint-disable-line max-len
import CheckboxTheme
  from '@shipae/components-sandbox/component/checkbox/theme/shipa-delivery'; // eslint-disable-line max-len
import Select from '@shipae/components-sandbox/component/select';
import SelectTheme from '@shipae/components-sandbox/component/select/theme/neutral'; // eslint-disable-line max-len
import {
  getJoinDeliveryFleetData,
} from 'src/application/lazy/join-delivery-fleet/selectors';
import config from 'src/config';
import Checkbox from '@shipae/components-sandbox/component/checkbox';
import { sd300 } from '@shipae/components-sandbox/component/colors';
import { countries } from 'application/constants/countries';
import styled from 'styled-components';
import { loadComponent } from 'src/application/core/control/actions';
import {
  ConsentStatement,
  FlexChild,
  Form,
  GeneralError,
  RecaptchaError,
} from '../shared.styles';
import { createTitleOptions } from './options';

const CustomCheckboxTheme = {
  ...CheckboxTheme,
  Main: styled(CheckboxTheme.Main)`
    height: auto;
  `,
};

const JoinDeliveryFleet: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('join-delivery-fleet'));

    return () => dispatch(resetForm);
  }, []);

  const { t } = useTranslation();
  const {
    isSuccess,
    isLoading,
    title,
    firstName,
    lastName,
    phoneNumber,
    email,
    vehicle,
    currentDesignation,
    currentLocation,
    resume,
    inviteCode,
    recaptcha,
    isPolicyChecked,
    generalError,
  } = useSelector(getJoinDeliveryFleetData);

  if (isSuccess) {
    if (typeof document !== 'undefined') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return null;
  }

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();

      dispatch(submitForm);
    }}
    >
      <FlexChild width="15%" hasSmallRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('title')}
          hasValue={!!title.value}
          error={t(title.error)}
          disabled={isLoading}
        >
          <Select
            theme={SelectTheme}
            error={!!title.error}
            options={createTitleOptions(t)}
            value={{
              label: title.value,
              value: title.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('title')(option?.label || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="45%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('firstName')}
          hasValue={!!firstName.value}
          error={t(firstName.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={firstName.value}
            error={!!firstName.error}
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('firstName')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="40%">
        <FormElement
          theme={FormElementTheme}
          label={t('lastName')}
          hasValue={!!lastName.value}
          error={t(lastName.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={lastName.value}
            error={!!lastName.error}
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('lastName')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="60%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('email')}
          hasValue={!!email.value}
          error={t(email.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="email"
            value={email.value}
            error={!!email.error}
            onChange={
            (newVal) => dispatch(
              setRequiredEmail('email')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="40%">
        <FormElement
          theme={FormElementTheme}
          label={t('vehicle')}
          hasValue={!!vehicle.value}
          error={t(vehicle.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={vehicle.value}
            error={!!vehicle.error}
            onChange={
                (newVal) => dispatch(
                  setInput('vehicle')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="60%" hasRightPadding>
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          required
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={PhoneInputTheme}
        />
      </FlexChild>
      <FlexChild width="40%">
        <FormElement
          theme={FormElementTheme}
          label={t('designation')}
          hasValue={!!currentDesignation.value}
          error={t(currentDesignation.error)}
          disabled={isLoading}
        >
          <Input
            theme={InputTheme}
            type="text"
            value={currentDesignation.value}
            error={!!currentDesignation.error}
            onChange={
              (newVal) => dispatch(
                setInput('currentDesignation')(newVal),
              )
            }
          />
        </FormElement>
      </FlexChild>

      <FlexChild width="60%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('currentLocation')}
          hasValue={!!currentLocation.value}
          error={t(currentLocation.error)}
          disabled={isLoading}
          required
        >
          <Select
            theme={SelectTheme}
            error={!!currentLocation.error}
            options={countries}
            value={{
              label: currentLocation.value,
              value: currentLocation.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('currentLocation')(option?.label || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="40%">
        <FormElement
          theme={FormElementTheme}
          label={t('resume')}
          hasValue={!!resume.value}
          error={t(resume.error)}
          disabled={isLoading}
          required
        >
          <FileInput
            accept=".pdf,.doc,.docx"
            value={resume.value}
            error={!!resume.error}
            onChange={
            (newVal) => {
              if (Array.isArray(newVal)) {
                return;
              }
              dispatch(
                setRequiredPdfOrWordFile('resume')(newVal),
              );
            }
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('inviteCode')}
          hasValue={!!inviteCode.value}
          error={t(inviteCode.error)}
          disabled={isLoading}
        >
          <Input
            theme={InputTheme}
            type="text"
            value={inviteCode.value}
            error={!!inviteCode.error}
            onChange={
                (newVal) => dispatch(
                  setInput('inviteCode')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <ConsentStatement>
        <FormElement
          theme={FormElementTheme}
          hasValue={isPolicyChecked.value.agreed}
          error={t(isPolicyChecked.error)}
          disabled={isLoading}
          data-test="delivery-fleet-policy"
          required
        >
          <Checkbox
            checked={isPolicyChecked.value.agreed}
            onChange={(
              val: boolean,
            ) => dispatch(
              setRequiredCheckbox(
                'isPolicyChecked',
                'agreed',
              )(!!val),
            )}
            color={sd300()}
            theme={CustomCheckboxTheme}
            label={t('JoinOurDeliveryFleet.agreeToPolicy')}
          />
        </FormElement>
      </ConsentStatement>
      <FlexChild width="100%" marginBottom="4rem">
        <Recaptcha
          sitekey={
            process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <RecaptchaError visible={!!recaptcha.error}>
          {t(recaptcha.error)}
        </RecaptchaError>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%" data-test="sec-get-in-touch-general-error">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('send')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default JoinDeliveryFleet;
