import FormElement from '@shipae/components-sandbox/component/form-element';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Recaptcha from 'react-google-recaptcha';
import Select from '@shipae/components-sandbox/component/select';
import SelectTheme from '@shipae/components-sandbox/component/select/theme/shipa-delivery'; // eslint-disable-line max-len
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/shipa-delivery'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/shipa-delivery'; // eslint-disable-line max-len
import MultiInput from '@shipae/components-sandbox/component/multi-input';
import MultiInputTheme
  from '@shipae/components-sandbox/component/multi-input/theme/shipa-delivery'; // eslint-disable-line max-len
import Input from '@shipae/components-sandbox/component/input';
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/shipa-delivery'; // eslint-disable-line max-len
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
import PhoneInputTheme
  from '@shipae/components-sandbox/component/phone-input/theme/shipa-delivery'; // eslint-disable-line max-len
import {
  resetForm,
  setRequiredInput,
  setRequiredPhoneInput,
  submitForm,
} from 'src/application/lazy/sd-consumer-callback/actions';
import {
  getSdConsumerCallbackData,
} from 'src/application/lazy/sd-consumer-callback/selectors';
import { useTranslation } from 'src/views/misc/localization';
import config from 'src/config';
import { loadComponent } from 'src/application/core/control/actions';
import {
  Container,
  FlexChild,
  Form,
  GeneralError,
  RecaptchaError,
} from '../shared.styles';
import { createReasonOptions } from './options';

const SDConsumerCallBack: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('sd-consumer-callback'));

    return () => dispatch(resetForm);
  }, []);
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    recaptcha,
    name,
    phoneNumber,
    orderNumbers,
    reason,
    generalError,
  } = useSelector(getSdConsumerCallbackData);

  const reasonOptions = useMemo(
    () => createReasonOptions(t),
    [t],
  );
  const reasonLabel = useMemo(
    () => {
      if (!reason.value) return '';
      const option = reasonOptions.find((o) => o.value === reason.value);

      return option?.label || '';
    },
    [t, reason.value, reasonOptions],
  );

  if (isSuccess) {
    if (typeof document !== 'undefined') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return null;
  }

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();

      dispatch(submitForm);
    }}
    >
      <FlexChild width="100%">
        {/* instead of hasRightPadding, using calc */}
        <Container width="40%">
          <FormElement
            theme={FormElementTheme}
            label={t('name')}
            hasValue={!!name.value}
            error={t(name.error)}
            disabled={isLoading}
            required
          >
            <Input
              theme={InputTheme}
              type="text"
              value={name.value}
              error={!!name.error}
              onChange={
              (newVal) => dispatch(
                setRequiredInput('name')(newVal),
              )
              }
            />
          </FormElement>
        </Container>
      </FlexChild>
      <FlexChild hasRightPadding width="60%">
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          required
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={PhoneInputTheme}
        />
      </FlexChild>
      <FlexChild width="100%">
        {/* instead of hasRightPadding, using calc */}
        <Container width="calc(75% - 4rem)">
          <FormElement
            theme={FormElementTheme}
            label={t('orderNumbers')}
            hasValue={!!orderNumbers.value}
            error={t(orderNumbers.error)}
            disabled={isLoading}
            required
          >
            <MultiInput
              value={orderNumbers.value.split(',').filter((v: string) => !!v)}
              theme={MultiInputTheme}
              error={!!orderNumbers.error}
              onChange={(val: Array<string>) => {
                dispatch(setRequiredInput('orderNumbers')(val.join(',')));
              }}
            />
          </FormElement>
        </Container>
      </FlexChild>
      <FlexChild>
        <FormElement
          theme={FormElementTheme}
          label={t('selectReasonForCalling')}
          hasValue={!!reason.value}
          error={t(reason.error)}
          disabled={isLoading}
          required
        >
          <Select
            theme={SelectTheme}
            error={!!reason.error}
            options={reasonOptions}
            value={{
              label: reasonLabel,
              value: reason.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('reason')(option?.value || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="100%" marginBottom="4rem">
        <Recaptcha
          sitekey={
            process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <RecaptchaError visible={!!recaptcha.error}>
          {t(recaptcha.error)}
        </RecaptchaError>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%" data-test="sec-get-in-touch-general-error">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('callMeBack')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default SDConsumerCallBack;
