import { AppState } from '../../state';
import { initialState, SdWorkWithUsData } from './reducer';

export const getSdWorkWithUsIsModuleLoaded = (
  state: AppState,
): boolean => (
  state.sdWorkWithUs
    ? state.sdWorkWithUs.isModuleLoaded
    : initialState.isModuleLoaded
);

export const getSdWorkWithUsData = (
  state: AppState,
): SdWorkWithUsData => (
  state.sdWorkWithUs
    ? state.sdWorkWithUs
    : initialState
);
