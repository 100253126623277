import { AppState } from '../../state';
import { initialState, TrackShipmentData } from './reducer';

export const getTrackShipmentData = (
  state: AppState,
): TrackShipmentData => (
  state.trackShipment
    ? state.trackShipment
    : initialState
);
