import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Recaptcha from 'react-google-recaptcha';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
import { useTranslation } from 'src/views/misc/localization';
import {
  resetForm,
  setRequiredEmail,
  setRequiredInput,
  setRequiredPhoneInput,
  submitForm,
} from 'src/application/lazy/sec-callback/actions';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/shipa-ecommerce'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/shipa-ecommerce'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/shipa-ecommerce'; // eslint-disable-line max-len
import PhoneInputTheme
  from '@shipae/components-sandbox/component/phone-input/theme/shipa-ecommerce'; // eslint-disable-line max-len
import {
  getSecCallbackData,
} from 'src/application/lazy/sec-callback/selectors';
import config from 'src/config';
import { loadComponent } from 'src/application/core/control/actions';
import {
  FlexChild,
  Form,
  Container,
  RecaptchaError,
  GeneralError,
} from '../shared.styles';

const SECCallBackForm: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('sec-callback'));

    return () => dispatch(resetForm);
  }, []);
  const { t } = useTranslation();
  const {
    isSuccess,
    isLoading,
    name,
    accountNumber,
    phoneNumber,
    email,
    recaptcha,
    generalError,
  } = useSelector(getSecCallbackData);

  if (isSuccess) {
    if (typeof document !== 'undefined') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return null;
  }

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();

      dispatch(submitForm);
    }}
    >
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('name')}
          hasValue={!!name.value}
          error={t(name.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={name.value}
            error={!!name.error}
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('name')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="35%">
        <FormElement
          theme={FormElementTheme}
          label={t('accountNumber')}
          hasValue={!!accountNumber.value}
          error={t(accountNumber.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={accountNumber.value}
            error={!!accountNumber.error}
            onChange={
            (newVal) => dispatch(
              setRequiredInput('accountNumber')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="60%">
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={PhoneInputTheme}
        />
      </FlexChild>
      <FlexChild width="100%">
        {/* instead of hasRightPadding, using calc */}
        <Container width="calc(50% - 4rem)">
          <FormElement
            theme={FormElementTheme}
            label={t('email')}
            hasValue={!!email.value}
            error={t(email.error)}
            disabled={isLoading}
            required
          >
            <Input
              theme={InputTheme}
              type="email"
              value={email.value}
              error={!!email.error}
              onChange={
            (newVal) => dispatch(
              setRequiredEmail('email')(newVal),
            )
          }
            />
          </FormElement>
        </Container>
      </FlexChild>
      <FlexChild width="100%" marginBottom="4rem">
        <Recaptcha
          sitekey={
            process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <RecaptchaError visible={!!recaptcha.error}>
          {t(recaptcha.error)}
        </RecaptchaError>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%" data-test="sec-get-in-touch-general-error">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('callMeBack')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default SECCallBackForm;
