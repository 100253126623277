import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import FormElement from '@shipae/components-sandbox/component/form-element';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/neutral'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral'; // eslint-disable-line max-len
import MultiInput from '@shipae/components-sandbox/component/multi-input';
import MultiInputTheme
  from '@shipae/components-sandbox/component/multi-input/theme/neutral'; // eslint-disable-line max-len
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'src/views/misc/localization';
import {
  getTrackShipmentData,
} from 'src/application/lazy/track-shipment/selectors';
import {
  resetForm,
  setFormSuccess,
  setRequiredInput,
  submitForm,
} from 'src/application/lazy/track-shipment/actions';
import { parseQueryString } from 'src/views/utils/parse-query-string';
import { loadComponent } from 'src/application/core/control/actions';
import { getTracking } from 'src/application/lazy/tracking/selectors';
import { FlexChild, Form, GeneralError } from '../shared.styles';

const TrackShipment: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('track-shipment'));

    return () => dispatch(resetForm);
  }, []);
  const location = useLocation();

  const { t } = useTranslation();
  const {
    isLoading,
    numbers,
    generalError,
  } = useSelector(getTrackShipmentData);

  const {
    moduleLoaded,
  } = useSelector(getTracking);

  useEffect(() => {
    if (!moduleLoaded || !location || !location.search) {
      return;
    }
    const query = parseQueryString(location.search);

    if (query.trackingno) {
      dispatch(setRequiredInput('numbers')(query.trackingno));
      dispatch(setFormSuccess(true));
    }
  }, [moduleLoaded]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        dispatch(submitForm);
      }}
      data-test="track-shipment-form"
    >
      <FlexChild width="100%">
        <FormElement
          theme={FormElementTheme}
          label={t('enterTrackingOrAwbNumber')}
          hasValue={!!numbers.value}
          error={t(numbers.error)}
          disabled={isLoading}
          required
        >
          <MultiInput
            value={numbers.value.split(',').filter((v: string) => !!v)}
            theme={MultiInputTheme}
            separators={[',', ' ', 'Enter']}
            error={!!numbers.error}
            onChange={(val: Array<string>) => {
              dispatch(setRequiredInput('numbers')(val.join(',')));
            }}
          />
        </FormElement>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%" data-test="sec-get-in-touch-general-error">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('track')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default TrackShipment;
