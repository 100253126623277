import React, { useMemo } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
import { useTranslation } from 'src/views/misc/localization';
import {
  setRequiredEmail,
  setRequiredInput,
  setRequiredPhoneInput,
  submitSecondStep,
} from 'src/application/lazy/sd-business-callback/actions';
import Select from '@shipae/components-sandbox/component/select';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/neutral'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/neutral'; // eslint-disable-line max-len
import SelectTheme
  from '@shipae/components-sandbox/component/select/theme/neutral'; // eslint-disable-line max-len
import PhoneInputTheme
  from '@shipae/components-sandbox/component/phone-input/theme/neutral'; // eslint-disable-line max-len
import {
  getSdBusinessCallbackData,
} from 'src/application/lazy/sd-business-callback/selectors';
import {
  FlexChild,
  Form,
  Container,
  GeneralError,
} from '../shared.styles';
import { createReasonOptions } from './options';

const RequestCallback: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading,
    name,
    accountNumber,
    phoneNumber,
    email,
    reason,
    generalError,
  } = useSelector(getSdBusinessCallbackData);

  const reasonOptions = useMemo(
    () => createReasonOptions(t),
    [t],
  );
  const reasonLabel = useMemo(
    () => {
      if (!reason.value) return '';
      const option = reasonOptions.find((o) => o.value === reason.value);

      return option?.label || '';
    },
    [t, reason.value, reasonOptions],
  );

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();

      dispatch(submitSecondStep);
    }}
    >
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('name')}
          hasValue={!!name.value}
          error={t(name.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={name.value}
            error={!!name.error}
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('name')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="35%">
        <FormElement
          theme={FormElementTheme}
          label={t('accountNumber')}
          hasValue={!!accountNumber.value}
          error={t(accountNumber.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={accountNumber.value}
            error={!!accountNumber.error}
            onChange={
            (newVal) => dispatch(
              setRequiredInput('accountNumber')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="60%">
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={PhoneInputTheme}
        />
      </FlexChild>
      <FlexChild width="100%">
        {/* instead of hasRightPadding, using calc */}
        <Container width="calc(50% - 4rem)">
          <FormElement
            theme={FormElementTheme}
            label={t('email')}
            hasValue={!!email.value}
            error={t(email.error)}
            disabled={isLoading}
            required
          >
            <Input
              theme={InputTheme}
              type="email"
              value={email.value}
              error={!!email.error}
              onChange={
            (newVal) => dispatch(
              setRequiredEmail('email')(newVal),
            )
          }
            />
          </FormElement>
        </Container>
      </FlexChild>
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('selectReasonForCalling')}
          hasValue={!!reason.value}
          error={t(reason.error)}
          disabled={isLoading}
          required
        >
          <Select
            theme={SelectTheme}
            error={!!reason.error}
            options={reasonOptions}
            value={{ label: reasonLabel, value: reason.value }}
          // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('reason')(option?.value || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('callMeBack')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default RequestCallback;
