import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Recaptcha from 'react-google-recaptcha';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import { useTranslation } from 'src/views/misc/localization';
import {
  setRequiredInput,
  submitFirstStep,
} from 'src/application/lazy/sd-business-callback/actions';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';

import {
  getSdBusinessCallbackData,
} from 'src/application/lazy/sd-business-callback/selectors';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/neutral'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/neutral'; // eslint-disable-line max-len
import config from 'src/config';
import {
  menuToggle,
  setHeaderSubmenu,
} from 'src/application/core/header/actions';
import { getHeader } from 'src/application/core/header/selectors';
import {
  FlexChild,
  Form,
  GeneralError,
  RecaptchaError,
  WrongAccountError,
} from '../shared.styles';

function getWindowWidth() {
  if (typeof window === 'undefined') return null;

  const { innerWidth: width } = window;
  return width;
}

const VerifyAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading,
    accountNumber,
    recaptcha,
    generalError,
    isWrongAccountNumber,
  } = useSelector(getSdBusinessCallbackData);
  const {
    getInTouchId,
  } = useSelector(getHeader);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openGetInTouch = () => {
    if (!windowWidth || !getInTouchId) return;

    if (windowWidth > 768) {
      dispatch(setHeaderSubmenu(`sec-${ getInTouchId }`));
    } else {
      dispatch(menuToggle(true));
      dispatch(setHeaderSubmenu(`mob-sec-${ getInTouchId }`));
    }
  };

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();

      dispatch(submitFirstStep);
    }}
    >
      <FlexChild>
        <FormElement
          theme={FormElementTheme}
          label={t('accountNumber')}
          hasValue={!!accountNumber.value}
          error={t(accountNumber.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={accountNumber.value}
            error={!!accountNumber.error}
            onChange={
              (newVal) => dispatch(
                setRequiredInput('accountNumber')(newVal),
              )
              }
          />
        </FormElement>
      </FlexChild>
      {isWrongAccountNumber ? (
        <FlexChild width="100%">
          <WrongAccountError>
            {t('wrongAccountNumber')}
            .
            {' '}
            <span
              tabIndex={0}
              role="button"
              onClick={() => openGetInTouch()}
              onKeyPress={() => openGetInTouch()}
            >
              {t('contactUsForAssistance')}
            </span>
          </WrongAccountError>
        </FlexChild>
      ) : null}
      {generalError.message ? (
        <FlexChild width="100%">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%" marginBottom="4rem">
        <Recaptcha
          sitekey={
            process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <RecaptchaError visible={!!recaptcha.error}>
          {t(recaptcha.error)}
        </RecaptchaError>
      </FlexChild>
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('verifyAccount')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default VerifyAccount;
