import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Recaptcha from 'react-google-recaptcha';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import PhoneInput from '@shipae/components-sandbox/component/phone-input';
import { useTranslation } from 'src/views/misc/localization';
import {
  resetForm,
  setInput,
  setRequiredCheckbox,
  setRequiredEmail,
  setRequiredInput,
  setRequiredPdfOrWordFile,
  setRequiredPhoneInput,
  submitForm,
} from 'src/application/lazy/submit-resume/actions';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import ButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme/neutral'; // eslint-disable-line max-len
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/neutral'; // eslint-disable-line max-len
import PhoneInputTheme
  from '@shipae/components-sandbox/component/phone-input/theme/neutral'; // eslint-disable-line max-len
import Select from '@shipae/components-sandbox/component/select';
import SelectTheme from '@shipae/components-sandbox/component/select/theme/neutral'; // eslint-disable-line max-len
import Checkbox from '@shipae/components-sandbox/component/checkbox';
import CheckboxTheme
  from '@shipae/components-sandbox/component/checkbox/theme/shipa-delivery'; // eslint-disable-line max-len
import FileInput from '@shipae/components-sandbox/component/file-input';
import {
  getSubmitResumeData,
} from 'application/lazy/submit-resume/selectors';
import config from 'src/config';
import { countries } from 'src/application/constants/countries';
import { black } from '@shipae/components-sandbox/component/colors';
import styled from 'styled-components';
import { loadComponent } from 'src/application/core/control/actions';
import {
  ConsentStatement,
  Container,
  FlexChild,
  Form,
  GeneralError,
  RecaptchaError,
} from '../shared.styles';
import { createYearsOfExperienceOptions } from './options';

const CustomCheckboxTheme = {
  ...CheckboxTheme,
  Main: styled(CheckboxTheme.Main)`
    height: auto;
  `,
};

const SubmitYourResumeForm: React.FC = () => {
  const dispatch = useDispatch();
  useEffect((): () => void => {
    dispatch(loadComponent('submit-resume'));

    return () => dispatch(resetForm);
  }, []);
  const { t } = useTranslation();
  const {
    isSuccess,
    isLoading,
    firstName,
    lastName,
    currentLocation,
    phoneNumber,
    email,
    resume,
    designation,
    yearsOfExperience,
    role,
    linkedinUrl,
    recaptcha,
    isPolicyChecked,
    generalError,
  } = useSelector(getSubmitResumeData);

  if (isSuccess) {
    if (typeof document !== 'undefined') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return null;
  }

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();

      dispatch(submitForm);
    }}
    >
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('firstName')}
          hasValue={!!firstName.value}
          error={t(firstName.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={firstName.value}
            error={!!firstName.error}
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('firstName')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          label={t('lastName')}
          hasValue={!!lastName.value}
          error={t(lastName.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="text"
            value={lastName.value}
            error={!!lastName.error}
            onChange={
                (newVal) => dispatch(
                  setRequiredInput('lastName')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('currentLocation')}
          hasValue={!!currentLocation.value}
          error={t(currentLocation.error)}
          disabled={isLoading}
          required
        >
          <Select
            theme={SelectTheme}
            error={!!currentLocation.error}
            options={countries}
            value={{
              label: currentLocation.value,
              value: currentLocation.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setRequiredInput('currentLocation')(option?.label || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <PhoneInput
          inputLabel={t('phoneNumber')}
          prefixPlaceholder={t('countryCode')}
          value={!!phoneNumber.number || !!phoneNumber.countryCode ? {
            prefix: phoneNumber.countryCode ? {
              label: phoneNumber.countryCode,
              value: phoneNumber.countryCode,
            } : null,
            number: phoneNumber.number,
          } : null}
          onChange={(val) => {
            dispatch(
              setRequiredPhoneInput(
                val?.prefix?.value || '',
                val?.number || '',
              ),
            );
          }}
          required
          disabled={isLoading}
          error={t(phoneNumber.error)}
          theme={PhoneInputTheme}
        />
      </FlexChild>
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('email')}
          hasValue={!!email.value}
          error={t(email.error)}
          disabled={isLoading}
          required
        >
          <Input
            theme={InputTheme}
            type="email"
            value={email.value}
            error={!!email.error}
            onChange={
            (newVal) => dispatch(
              setRequiredEmail('email')(newVal),
            )
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          label={t('resume')}
          hasValue={!!resume.value}
          error={t(resume.error)}
          disabled={isLoading}
          required
        >
          <FileInput
            accept=".pdf,.doc,.docx"
            value={resume.value}
            error={!!resume.error}
            onChange={
            (newVal) => {
              if (Array.isArray(newVal)) {
                return;
              }
              dispatch(
                setRequiredPdfOrWordFile('resume')(newVal),
              );
            }
          }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="100%">
        <Container width="calc(50% - 4rem)">
          <FormElement
            theme={FormElementTheme}
            label={t('designation')}
            hasValue={!!designation.value}
            error={t(designation.error)}
            disabled={isLoading}
          >
            <Input
              theme={InputTheme}
              type="text"
              value={designation.value}
              error={!!designation.error}
              onChange={
                (newVal) => dispatch(
                  setInput('designation')(newVal),
                )
                }
            />
          </FormElement>
        </Container>
      </FlexChild>
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('yearsOfExperience')}
          hasValue={!!yearsOfExperience.value}
          error={t(yearsOfExperience.error)}
          disabled={isLoading}
        >
          <Select
            theme={SelectTheme}
            error={!!yearsOfExperience.error}
            options={createYearsOfExperienceOptions()}
            value={{
              label: yearsOfExperience.value,
              value: yearsOfExperience.value,
            }}
            // Becase value type is polymorphic, we need to type-guard it!
            onChange={(option) => !Array.isArray(option) && dispatch(
              setInput('yearsOfExperience')(option?.value || ''),
            )}
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%">
        <FormElement
          theme={FormElementTheme}
          label={t('role')}
          hasValue={!!role.value}
          error={t(role.error)}
          disabled={isLoading}
        >
          <Input
            theme={InputTheme}
            type="text"
            value={role.value}
            error={!!role.error}
            onChange={
                (newVal) => dispatch(
                  setInput('role')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <FlexChild width="50%" hasRightPadding>
        <FormElement
          theme={FormElementTheme}
          label={t('linkedinUrl')}
          hasValue={!!linkedinUrl.value}
          error={t(linkedinUrl.error)}
          disabled={isLoading}
        >
          <Input
            theme={InputTheme}
            type="text"
            value={linkedinUrl.value}
            error={!!linkedinUrl.error}
            onChange={
                (newVal) => dispatch(
                  setInput('linkedinUrl')(newVal),
                )
                }
          />
        </FormElement>
      </FlexChild>
      <ConsentStatement>
        <FormElement
          theme={FormElementTheme}
          hasValue={isPolicyChecked.value.agreed}
          error={t(isPolicyChecked.error)}
          disabled={isLoading}
          data-test="submit-resume-policy"
          required
        >
          <Checkbox
            checked={isPolicyChecked.value.agreed}
            onChange={(
              val: boolean,
            ) => dispatch(
              setRequiredCheckbox(
                'isPolicyChecked',
                'agreed',
              )(!!val),
            )}
            color={black()}
            theme={CustomCheckboxTheme}
            label={t('SubmitYourResume.agreeToPolicy')}
          />
        </FormElement>
      </ConsentStatement>
      <FlexChild width="100%" marginBottom="4rem">
        <Recaptcha
          sitekey={
            process.env.GATSBY_RECAPTCHA_CLIENT_KEY
            || config.defaultRecaptchaSiteKey
          }
          onChange={(response) => dispatch(
            setRequiredInput('recaptcha')(response || ''),
          )}
        />
        <RecaptchaError visible={!!recaptcha.error}>
          {t(recaptcha.error)}
        </RecaptchaError>
      </FlexChild>
      {generalError.message ? (
        <FlexChild width="100%" data-test="sec-get-in-touch-general-error">
          <GeneralError>
            {generalError.message}
          </GeneralError>
        </FlexChild>
      ) : null}
      <FlexChild width="100%">
        <PrimaryButton
          type="submit"
          loading={isLoading}
          theme={ButtonTheme}
        >
          {t('send')}
        </PrimaryButton>
      </FlexChild>
    </Form>
  );
};

export default SubmitYourResumeForm;
