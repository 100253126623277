import React, { FC, useContext } from 'react';
import styled from 'styled-components';

import MediaSectionComponent
  from '@shipae/components-sandbox/component/media-section';
import RichText from '@shipae/components-sandbox/component/rich-text';
import RichTextTheme
  from '@shipae/components-sandbox/component/rich-text/theme';

import { Forms } from 'src/application/enums/forms';
import { FormWithMedia } from 'src/infrastructure/gatsby/types/form-with-media';
import { calculateBreadcrumbs } from 'src/views/utils/calculate-breadcrumbs';
import { PageContext } from 'src/views/templates/context';
import ReactMarkdown from 'markdown-to-jsx';
import {
  getSdWorkWithUsData,
} from 'src/application/lazy/sd-work-with-us/selectors';
import {
  getSdBusinessCallbackData,
} from 'src/application/lazy/sd-business-callback/selectors';
import {
  getSecCallbackData,
} from 'src/application/lazy/sec-callback/selectors';
import { media } from 'src/views/theme';

import { useSelector } from 'react-redux';
import {
  useSuccessImageFallback,
} from 'src/infrastructure/hooks/use-success-image-fallback';
import { useTranslation } from 'src/views/misc/localization';
import {
  getSdConsumerCallbackData,
} from 'src/application/lazy/sd-consumer-callback/selectors';
import SmartLink from 'components/smart-link';
import {
  getSubmitResumeData,
} from 'src/application/lazy/submit-resume/selectors';
import {
  getJoinDeliveryFleetData,
} from 'src/application/lazy/join-delivery-fleet/selectors';
import {
  getSecGetInTouchForm,
} from 'src/application/lazy/sec-get-in-touch/selectors';
import {
  getTrackShipmentData,
} from 'src/application/lazy/track-shipment/selectors';
import {
  GrayBackgroundSection,
  ImageRegular,
  ImageSvg,
  Section,
  WhiteBox,
} from './form-with-media.styles';
import SDBusinessWorkWithUsForm from './sd-business-work-form';
import SDBusinessCallBackForm from './sd-business-call-back';
import SECCallBackForm from './sec-call-back';
import SDConsumerCallBack from './sd-consumer-call-back';
import SubmitYourResume from './submit-your-resume';
import JoinDeliveryFleet from './join-delivery-fleet';
import FormInfo from './form-info';
import SECGetInTouch from './sec-get-in-touch';
import TrackShipment from './track-shipment';
import gloabalReactMdOptions from '../shared/react-makdown-options';
import { ROUTES } from '../../constants/ROUTES';

type Props = Omit<FormWithMedia, 'type'>;

const FormWithMediaSection: React.FC<Props> = ({
  sectionId,
  formId,
  title,
  image,
  showBreadcrumbs,
  successImage,
  successText,
  layout,
}) => {
  let isFormSubmitted = false;

  const {
    isSuccess: isSDBusinessWorkWithUsSubmitted,
  } = useSelector(getSdWorkWithUsData);
  const {
    isSuccess: isSDBusinessCallBackSubmitted,
  } = useSelector(getSdBusinessCallbackData);
  const {
    isSuccess: isSECCallBackSubmitted,
  } = useSelector(getSecCallbackData);
  const {
    isSuccess: isSDConsumerCallBackSubmitted,
  } = useSelector(getSdConsumerCallbackData);
  const {
    isSuccess: isSubmitYourResumeSubmitted,
  } = useSelector(getSubmitResumeData);
  const {
    isSuccess: isJoinDeliveryFleetSubmitted,
  } = useSelector(getJoinDeliveryFleetData);
  const {
    isSuccess: isSECGetInTouchSubmitted,
  } = useSelector(getSecGetInTouchForm);
  const {
    isSuccess: isTrackShipmentSubmitted,
  } = useSelector(getTrackShipmentData);

  let FormComponent;
  switch (formId) {
    case Forms.SDBusinessWorkWithUs: {
      FormComponent = SDBusinessWorkWithUsForm as FC;
      isFormSubmitted = isSDBusinessWorkWithUsSubmitted;
      break;
    }
    case Forms.SDBusinessCallBack: {
      FormComponent = SDBusinessCallBackForm as FC;
      isFormSubmitted = isSDBusinessCallBackSubmitted;
      break;
    }
    case Forms.SECCallBack: {
      FormComponent = SECCallBackForm as FC;
      isFormSubmitted = isSECCallBackSubmitted;
      break;
    }
    case Forms.SDConsumerCallBack: {
      FormComponent = SDConsumerCallBack as FC;
      isFormSubmitted = isSDConsumerCallBackSubmitted;
      break;
    }
    case Forms.SubmitYourResume: {
      FormComponent = SubmitYourResume as FC;
      isFormSubmitted = isSubmitYourResumeSubmitted;
      break;
    }
    case Forms.JoinDeliveryFleet: {
      FormComponent = JoinDeliveryFleet as FC;
      isFormSubmitted = isJoinDeliveryFleetSubmitted;
      break;
    }
    case Forms.SECGetInTouch: {
      FormComponent = SECGetInTouch as FC;
      isFormSubmitted = isSECGetInTouchSubmitted;
      break;
    }
    case Forms.TrackShipment: {
      FormComponent = TrackShipment as FC;
      isFormSubmitted = isTrackShipmentSubmitted;
      break;
    }
    default:
      return null;
  }

  const pageContext = useContext(PageContext);
  const { locale, t } = useTranslation();

  const { image: fallbackImage } = useSuccessImageFallback();
  return (
    layout !== 'whiteBoxRightSided' ? (
      <Section
        id={sectionId}
        data-test="form-with-media"
      >
        <MediaSectionComponent
          breadcrumbsHome={{
            label: t('home'),
            url: ROUTES[locale].HOME,
          }}
          breadcrumbsLinks={
            showBreadcrumbs
              ? calculateBreadcrumbs({
                parentTitle: pageContext.pageSettings.parentPageTitle,
                parentPath: pageContext.pageSettings.parentPagePath,
                title: pageContext.title,
              })
              : null
          }
          linkComponent={SmartLink}
          media={{
            node: (
              image?.localFile?.childImageSharp?.fluid ? (
                <ImageRegular
                  fluid={
                    isFormSubmitted
                      ? (successImage?.localFile?.childImageSharp?.fluid
                        || fallbackImage?.childImageSharp?.fluid
                        || [])
                      : image.localFile?.childImageSharp.fluid
                  }
                  alt={
                    isFormSubmitted
                      ? (successImage?.alternativeText
                    || 'Okay')
                      : image.alternativeText || ''
                  }
                  objectFit="contain"
                  objectPosition="center center"
                />
              ) : (
                <ImageSvg
                  src={
                  isFormSubmitted
                    ? (successImage?.localFile?.publicURL
                      || fallbackImage?.publicURL)
                    : image?.localFile?.publicURL
                }
                  loading="lazy"
                  alt={
                    isFormSubmitted
                      ? (successImage?.alternativeText
                    || 'Okay')
                      : image.alternativeText || ''
                  }
                />
              )
            ),
            width: '44.8rem',
            position: 'right',
          }}
          mobile={{
            hideMedia: true,
          }}
        >
          <RichText theme={{
            ...RichTextTheme,
            Main: styled(RichTextTheme.Main)`
              margin-bottom: 3rem;

              h1 {
                ${ media.lg } {
                  font-size: 4.6rem;
                  line-height: 4.6rem;

                }

                ${ media.max(1300) } {
                  font-size: 3.8rem;
                  line-height: 3.8rem;
                }

                ${ media.max(1150) } {
                  font-size: 3rem;
                  line-height: 3rem;
                }
              }
            `,
          }}
          >
            <ReactMarkdown options={gloabalReactMdOptions}>
              {isFormSubmitted
                ? (successText || `## ${ t('formSubmitThankYou') }`)
                : title || ''}
            </ReactMarkdown>
          </RichText>
          <FormComponent />
        </MediaSectionComponent>
      </Section>
    ) : (
      <GrayBackgroundSection
        id={sectionId}
        data-test="form-with-media"
      >
        <FormInfo
          text={
            isFormSubmitted
              ? (successText || `## ${ t('formSubmitThankYou') }`)
              : title
          }
          image={
            image?.localFile?.childImageSharp?.fluid ? (
              <ImageRegular
                margin="10rem 0 0"
                fluid={
                  isFormSubmitted
                    ? (successImage?.localFile?.childImageSharp?.fluid
                      || fallbackImage?.childImageSharp?.fluid || [])
                    : image.localFile?.childImageSharp.fluid
                }
                alt={
                  isFormSubmitted
                    ? (successImage?.alternativeText
                    || 'Okay')
                    : image.alternativeText || ''
                }
                objectFit="contain"
                objectPosition="center left"
              />
          ) : (
            <ImageSvg
              margin="10rem 0 0"
              src={
              isFormSubmitted
                ? (successImage?.localFile?.publicURL
                  || fallbackImage?.publicURL)
                : image?.localFile?.publicURL
            }
              loading="lazy"
              alt={isFormSubmitted ? (
                successImage?.alternativeText
                || 'Okay'
              ) : image?.alternativeText || ''}
            />
          )
        }
        />
        <WhiteBox isHidden={isFormSubmitted}>
          <FormComponent />
        </WhiteBox>
      </GrayBackgroundSection>
    )
  );
};

export default FormWithMediaSection;
