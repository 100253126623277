import {
  SET_IS_MODULE_LOADED,
  SecGetInTouchActions,
  SetInputValidated,
  SET_INPUT_SEC_GET_IN_TOUCH,
  SET_FORM_LOADING_SEC_GET_IN_TOUCH,
  SetFormLoading,
  SET_PHONE_INPUT_SEC_GET_IN_TOUCH,
  SetPhoneInputValidated,
  SET_FORM_SUCCESS_SEC_GET_IN_TOUCH,
  SetFormSuccess,
  SET_CHECKBOX_SEC_GET_IN_TOUCH,
  SetCheckboxValidated,
  SET_CAMPAIGN_GET_IN_TOUCH,
  SetCampaign,
  SetGeneralErrorMessage,
  SET_GENERAL_ERROR_GET_IN_TOUCH,
  RESET_FORM_GET_IN_TOUCH,
  SET_MULTI_SELECT_GET_IN_TOUCH,
  SetMultiSelectValidated,
} from './actions';

export type SecGetInTouchData = {
  isModuleLoaded: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  generalError: {
    message?: string;
  };
  recaptcha: {
    value: string;
    error: string;
  };
  firstName: {
    value: string;
    error: string;
  };
  lastName: {
    value: string;
    error: string;
  };
  phoneNumber: {
    countryCode: string;
    number: string;
    error: string;
  };
  email: {
    value: string;
    error: string;
  };
  companyName: {
    value: string;
    error: string;
  };
  websiteLink: {
    value: string;
    error: string;
  };
  country: {
    value: string;
    error: string;
  };
  city: {
    value: string;
    error: string;
  };
  ordersPerMonth: {
    value: string;
    error: string;
  };
  hasTradeLicense: {
    value: string;
    error: string;
  };
  kindOfCommodities: {
    values: string[];
    error: string;
  };
  additionalDetails: {
    value: string;
    error: string;
  };
  agreeToTermsAndConditions: {
    value: {[key: string]: boolean};
    error: string;
  };
  campaign: {
    name: string;
    source: string;
    medium: string;
  }
}

export const initialState: SecGetInTouchData = {
  isModuleLoaded: false,
  isLoading: false,
  isSuccess: false,
  generalError: {
    message: '',
  },
  recaptcha: {
    value: '',
    error: '',
  },
  firstName: {
    value: '',
    error: '',
  },
  lastName: {
    value: '',
    error: '',
  },
  phoneNumber: {
    countryCode: '+971',
    number: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  companyName: {
    value: '',
    error: '',
  },
  websiteLink: {
    value: '',
    error: '',
  },
  country: {
    value: '',
    error: '',
  },
  city: {
    value: '',
    error: '',
  },
  ordersPerMonth: {
    value: '',
    error: '',
  },
  hasTradeLicense: {
    value: '',
    error: '',
  },
  kindOfCommodities: {
    values: [],
    error: '',
  },
  additionalDetails: {
    value: '',
    error: '',
  },
  agreeToTermsAndConditions: {
    value: {},
    error: '',
  },
  campaign: {
    name: '',
    source: '',
    medium: '',
  },
};

const SecGetInTouch = (
  state: SecGetInTouchData = initialState,
  action: SecGetInTouchActions,
): SecGetInTouchData => {
  switch (action.type) {
    case SET_IS_MODULE_LOADED:
      return {
        ...state,
        isModuleLoaded: true,
      };
    case SET_INPUT_SEC_GET_IN_TOUCH:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_CHECKBOX_SEC_GET_IN_TOUCH:
      return {
        ...state,
        ...(action as SetCheckboxValidated).payload,
      };
    case SET_MULTI_SELECT_GET_IN_TOUCH:
      return {
        ...state,
        ...(action as SetMultiSelectValidated).payload,
      };
    case SET_PHONE_INPUT_SEC_GET_IN_TOUCH:
      return {
        ...state,
        ...(action as SetPhoneInputValidated).payload,
      };
    case SET_FORM_LOADING_SEC_GET_IN_TOUCH: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_SEC_GET_IN_TOUCH: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SET_CAMPAIGN_GET_IN_TOUCH: {
      return {
        ...state,
        campaign: (action as SetCampaign).payload,
      };
    }
    case SET_GENERAL_ERROR_GET_IN_TOUCH: {
      return {
        ...state,
        generalError: {
          message: (action as SetGeneralErrorMessage).payload,
        },
      };
    }
    case RESET_FORM_GET_IN_TOUCH: {
      return initialState;
    }
    default:
      return state;
  }
};

export default SecGetInTouch;
